import { Dispatch, SetStateAction, useState, useEffect, useRef } from "react";
import { useCallback } from "react";

declare global {
  interface WindowEventMap {
    "local-storage": CustomEvent;
  }
}

type SetValue<T> = Dispatch<SetStateAction<T>>;

export default function useLocalStorage<T>(initialValue: T, key: string, fromStorage: Boolean = true): [T, SetValue<T>, (x: void) => void] {
  const firstMount = useRef(false);
  const getValue = useCallback((): T => {
    try {
      if (fromStorage) {


        const storage = window.localStorage.getItem(key);

        if (storage) {
          const data: T = JSON.parse(storage);

          if (data) return data;
          return initialValue;
        }
      }
      return initialValue;
    } catch (_) {
      return initialValue;
    }
  }, [initialValue, key, fromStorage]);

  const removeStorage = useCallback(() => window.localStorage.removeItem(key), []);

  const [state, setState] = useState(getValue);

  useEffect(() => {
    if (firstMount.current) localStorage.setItem(key, JSON.stringify(state));
    else firstMount.current = true;
    // if (!state) return;
  }, [state, key]);

  return [state, setState, removeStorage];
};
