import React, { useRef } from "react";

import { useTranslation } from "react-i18next";
import { CustomNavLink } from "../../CustomNavLink";
import { NAVBAR_ROUTES } from "../Header";

import { CloseButton } from "./CloseButton";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import { useRouter } from "next/router";
import { HocWithPortal } from "../../../hocs/hocWithPortal";

import { linkByPath } from "../../../utils/link/linkByPath";
import { HeaderMobileNavItem } from "../HeaderMobileNavItem/HeaderMobileNavItem";
export interface Menu {
  // shortPath: string;
  // language: string;
  hasBonds?: boolean;
  hasEurobonds?: boolean;
  hasStocks?: boolean;
  hasCorporates?: boolean;
  hasQA?: boolean;
  hasDemo?: boolean;
  onClick?(): void;
}

export const MenuComponent = ({
  // hasBonds,
  // hasEurobonds,
  // hasStocks,
  // hasCorporates,
  // hasDemo,
  hasQA,
  onClick,
}: Menu) => {
  const [t] = useTranslation();
  const ref = useRef(null);
  const { pathname } = useRouter();
  useOnClickOutside(ref, onClick);

  const shortPath = pathname.split("/");

  return (
    <aside className="nav-menu">
      <CloseButton onClick={onClick} />
      <ul className="nav-menu-container" ref={ref}>
        {NAVBAR_ROUTES.map(({ slug, titleKey, path, isPage, children }) => {
          const to = isPage ? `${path}` : `${pathname}${path}`;
          // if (slug === "demo" && !hasDemo) return null;
          // if (slug === "stocks" && !hasStocks) return null;
          // if (slug === "eurobonds" && !hasEurobonds) return null;
          if (slug === "questions" && !hasQA) return null;

          if (!!children) {
            if (!children.length) return;
            const to = linkByPath({
              isPage,
              path,
              shortPath,
              pathname,
            });

            return (
              <HeaderMobileNavItem
                key={slug}
                to={to}
                titleKey={titleKey}
                children={children}
                pathname={pathname}
                path={path}
                shortPath={shortPath}
                onClick={onClick}
              />
            );
          }

          return (
            <li key={slug} className="nav-menu__item-link">
              <CustomNavLink
                to={to}
                className="nav-menu__link"
                onClick={onClick}
              >
                {t(titleKey)}
              </CustomNavLink>
            </li>
          );
        })}
      </ul>
      <style jsx global>{`
        @import "./Menu.scss?";
      `}</style>
    </aside>
  );
};

export const Menu = HocWithPortal(MenuComponent);
