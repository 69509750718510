import { useQuery } from "react-query";
import { BROKER_ENDPOINT } from "../endpoint_types";

import { fetchHandler } from "../fetchHandler";
import { useTranslation } from "next-i18next";

export default function useBroker() {
  const {
    i18n: { language },
  } = useTranslation();

  return useQuery(["broker", language], () =>
    fetchHandler({
      to: BROKER_ENDPOINT,
      locale: language,
      dataName: "data",
      params: { key: process.env.API_DEFAULT_KEY },
    })
  );
}
