import React, { memo, useMemo } from "react";

import { Burger } from "../Burger";

import { HeaderNav } from "../HeaderNav";

// import useVideos from "../../../rq/queries/useVideos";

import useQA from "../../../rq/queries/useQA";
// import { QueryCache } from 'react-query';
// import useBonds from "../../../rq/queries/useBonds";
import { useRouter } from "next/router";
import { BondTypes } from "../../../types/bond_types";
export interface Nav {
  // shortPath: string;
  // language: string;
  hasBonds?: boolean;
  hasEurobonds?: boolean;
  hasCorporates?: boolean;
  hasStocks?: boolean;
  hasDemo?: boolean;
  hasQA?: boolean;
  onClick?(): void;
}

export const HeaderFullNav = memo(() => {
  const { pathname } = useRouter();
  // const { data = [] } = useVideos();

  const bondType = useMemo(() => {
    // const routes = ["eurobonds", "stocks"];

    // if (pathname.includes("eurobonds")) return BondTypes.eurobond_type;
    // if (pathname.includes("bonds")) return BondTypes.bond_type;
    // if (pathname.includes("corporates")) return BondTypes.corporate_type;
    // if (pathname.includes("stocks")) return BondTypes.share_type;

    if (pathname === "/") return BondTypes.all_type;
    return "";
    // return BondTypes.bond_type;
  }, [pathname]);

  // const { data: stocks = [] } = useBonds({ bondType: BondTypes.share_type });

  // const { data: eurobonds = [] } = useBonds({
  //   bondType: BondTypes.eurobond_type,
  // });

  // const [queryCache] = useState(new QueryCache());
  // const data = queryCache.findAll('videos');
  // const stocks = queryCache.findAll('shares');

  // const qa = queryCache.findAll('qa_bond');
  //! pathname.includes(pathname.includes("stocks") ? "qa_stock" : "qa_bond")
  // ['eurobonds','stocks'].some((route)=> pathname.includes(route))

  // const filter = (string: string, searchArray: string[]): boolean => {
  //   const regexStr = "(.*" + searchArray.join(")(.*") + ")";
  //   const searchRegEx = new RegExp(regexStr, "gi");
  //   return string.match(searchRegEx) !== null;
  // };

  const { data: qa = [] } = useQA({ bondType, enabled: !!bondType });

  return (
    <>
      <HeaderNav
        // hasDemo={!!data.length}
        // hasStocks={!!stocks.length}
        // hasEurobonds={!!eurobonds.length}
        hasQA={!!qa.length}
      />
      <Burger
        // hasDemo={!!data.length}
        // hasStocks={!!stocks.length}
        // hasEurobonds={!!eurobonds.length}
        hasQA={!!qa.length}
      />
      <style jsx global>{`
        @import "./HeaderNav.scss";
      `}</style>
    </>
  );
});
