import React from "react";
import Link from "next/link";
import Image from "next/image";
// import { importFiles } from "../../utils/importFiles";

// const images = importFiles(
//   require.context("../../../public/svg", false, /\.(png|jpe?g|svg)$/)
// );

const SocialItems = () => {
  const social = [
    { title: "telegram", link: "https://t.me/bond_ua_finance" },
    { title: "instagram", link: "https://instagram.com/bond_ua_finance" },
    { title: "facebook", link: "https://www.facebook.com/bond.ua.finance" },
  ];

  return (
    <div className="social-block">
      {social.map(({ title, link }, index) => {
        // const image = images[title];
        const image = `/svg/${title}.svg`;
        return (
          <Link href={link} key={index}>
            <a
              className="social-link"
              // className="footer-brand"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image
                // src="/svg/telegram.svg"
                src={image}
                alt={title}
                width={30}
                height={30}
              />
            </a>
          </Link>
        );
      })}
    </div>
  );
};

export { SocialItems };
