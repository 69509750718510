import React from "react";

import Link from "next/link";
import { useRouter } from "next/router";
import classNames from "classnames";

interface CustomNavLink {
  children: JSX.Element;
  className: string;
  to: string;
  [props: string]: any;
  onClick?(): void;
}

export const CustomNavLink = ({
  children,
  to,
  className,
  onClick,
  ...props
}: CustomNavLink) => {
  const { asPath } = useRouter();
  const [path, hash] = asPath.split("#");

  const isActive =
    path === to ||
    (to !== "/" && path.split("/").length > 1 && path.includes(to)) ||
    // (to === "/#quote" && asPath === "/") ||
    (hash === "quote" ? to === asPath : to === `${path}#quote`);

  const classNav = classNames(className, {
    [`${className}--active`]: !!isActive,
  });

  return (
    <Link href={to}>
      <a {...props} className={classNav} {...(!!onClick && { onClick })}>
        {children}
      </a>
    </Link>
  );
};
