import { appWithTranslation } from "next-i18next";
import "../../styles/globals.scss";
import { Layout } from "../components/Layout";
import { ProvideMediaContext } from "../context/mediaQuery/MediaQueryContext";
import Script from "next/script";
import nextI18nextConfig from "../../next-i18next.config";
import { QueryClient, QueryClientProvider, Hydrate } from "react-query";
import type { AppProps } from "next/app";
import { useState } from "react";
import { ScrollToAnchor } from "../hooks/ScrollToAnchor";

function App({ Component, pageProps }: AppProps) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            // refetchOnReconnect:false,

            // default: true
          },
        },
      })
  );

  const { tags, dehydratedState } = pageProps;

  return (
    <>
      <Script id="google-tag-manager-script" strategy="afterInteractive">
        {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-N4BKCPGT');
      `}
      </Script>
      <ScrollToAnchor />
      <QueryClientProvider client={queryClient}>
        <Hydrate state={dehydratedState}>
          <ProvideMediaContext>
            <Layout tags={tags}>
              <Component {...pageProps} />
            </Layout>
          </ProvideMediaContext>
        </Hydrate>
      </QueryClientProvider>
    </>
  );
}

export default appWithTranslation(App, nextI18nextConfig as any);
