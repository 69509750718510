import React, { memo } from "react";

import { CustomNavLink } from "../../CustomNavLink";
import { useTranslation } from "react-i18next";
import { linkByPath } from "../../../utils/link/linkByPath";
import { NavBarRoute } from "..";

import classNames from "classnames";

export const HeaderMobileNavItem = memo(
  ({
    titleKey,
    pathname,
    shortPath,
    children,
    to,
    className = "header-nav__link",
    onClick,
  }: {
    isMobile?: boolean;
    titleKey: string;
    pathname: string;
    path: string;
    shortPath: string[];
    to: string;
    // isPage?: boolean;
    className?: string;
    children: NavBarRoute[];
    onClick?(): void;
  }) => {
    const [t] = useTranslation();

    const isActive = children.some(({ slug }) => to.includes(slug));

    return (
      <li className="header-nav__items nav-menu__item-link">
        <button
          className={classNames(className, {
            [`${className}--active`]: !!isActive,
          })}
          // onClick={onClick}
        >
          {t(titleKey)}
        </button>

        <ul className={classNames("nav-menu__mobile-group-item")}>
          {children.map(({ slug, titleKey, path, isPage }) => {
            const to = linkByPath({
              isPage,
              path,
              shortPath,
              pathname,
            });

            return (
              <li key={slug} className={"nav-menu__mobile-group-item-link"}>
                <CustomNavLink
                  to={to}
                  onClick={onClick}
                  className="nav-menu__group-link"
                >
                  {t(titleKey)}
                </CustomNavLink>
              </li>
            );
          })}
        </ul>
      </li>
    );
  }
);
