export const getSeoFields = ({
  tagTitle,
  tagDescription,
  tagKeywords,
  ogType = "website",
  ogSiteName = "Bond UA",
  ogTitle,
  ogDescription,
  ogImage, // = "/banner.jpg",
  ogImageWidth = 1200,
  ogImageHeight = 630,
  ogImageAlt = "Bond UA",
  // ogImageType = "image/png",
  alternate,
  ogUrl = alternate,
  icon = "/favicon.ico",
  iconTouch = "/logo192.png",
  // googleTag = null,
} = {}) => ({
  tagTitle,
  tagDescription,
  tagKeywords,
  ogType,
  ogSiteName,
  ogTitle,
  ogDescription,
  ogImage,
  ogImageWidth,
  ogImageHeight,
  ogImageAlt,
  // ogImageType,
  ogUrl,
  alternate,
  icon,
  iconTouch,
  // googleTag,
});
