import React from 'react';

interface ButtonWithChild {
  className?: string;
  onClick(): void;
  children: JSX.Element;
}

export const ButtonWithChild = ({ className, onClick, children }: ButtonWithChild) => {
  return (
    <button className={className} onClick={onClick}>
      {children}
    </button>
  );
};
