import React, { memo } from "react";
import { Menu } from "../Menu";
import { useToggle } from "../../../hooks/useToggle";
import { Nav } from "../HeaderFullNav";

export const Burger = memo(
  ({
    // hasDemo = false,
    // hasBonds = true,
    // hasEurobonds = true,
    // hasStocks = true,
    // hasCorporates = true,
    hasQA = false,
  }: Nav) => {
    const [show, toggle, off] = useToggle();
    return (
      <>
        <div className="nav-burger" onClick={toggle}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        {show && (
          <Menu
            onClick={off}
            // hasDemo={hasDemo}
            // hasBonds={hasBonds}
            // hasStocks={hasStocks}
            // hasEurobonds={hasEurobonds}
            // hasCorporates={hasCorporates}
            hasQA={hasQA}
          />
        )}
      </>
    );
  }
);
