import React from 'react';
import ReactDOM from 'react-dom';

export const HocWithPortal = (Component, withPortal = true) => (props) => {
  if (!withPortal) return <Component />;
  return ReactDOM.createPortal(
    <Component {...props} />,
    document.querySelector('#modal')
  );
};
