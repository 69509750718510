import React, { useEffect, useCallback, useRef, memo } from 'react';
import { useTranslation } from 'react-i18next';

// import RuSvg from '../../../imgs/svg/ru_flag.svg';
// import EnSvg from '../../../imgs/svg/en_flag.svg';
// import UkSvg from '../../../imgs/svg/uk_flag.svg';

import { ButtonWithChild } from '../../ButtonWithChild';
import { useRouter } from 'next/router';
import { useToggle } from '../../../hooks/useToggle';
import { useOnClickOutside } from '../../../hooks/useOnClickOutside';
import { useLocalStorage } from '../../../hooks';
import nextI18nextConfig from '../../../../next-i18next.config';

const languages = [
  {
    slug: 'ru',
    // icon: <RuSvg />,
    title: 'RU',
  },
  {
    slug: 'uk',
    // icon: <UkSvg />,
    title: 'UK',
  },
  {
    slug: 'en',
    // icon: <EnSvg />,
    title: 'EN',
  },
];

export const HeaderLanguage = memo(() => {
  const [, { language }] = useTranslation();

  const router = useRouter();
  const { pathname, query, asPath } = router;

  const [visible, toggle, hide] = useToggle(false);
  const ref = useRef() as React.MutableRefObject<HTMLInputElement>;

  useOnClickOutside(ref, hide);

  const [localLanguage, setLocaleLanguage] = useLocalStorage(language, '_language', language === nextI18nextConfig.i18n.defaultLocale);

  useEffect(() => {
    if (!localLanguage || language === localLanguage) return;
    router.push({ pathname, query }, asPath, { locale: localLanguage });

  }, [pathname, query, asPath, localLanguage]);

  const changeLanguage = useCallback(
    (language: string) => {
      hide();
      setLocaleLanguage(language);
      // router.push({ pathname, query }, asPath, { locale: language });
    },
    [pathname, query, asPath, setLocaleLanguage]
  );

  return (
    <div className="header-lang lang" ref={ref}>
      {languages
        .filter(({ slug }) => slug === language)
        .map(({ title }) => (
          <ButtonWithChild
            onClick={toggle}
            className="lang__selected"
            key={title}
          >
            {/* <> 
              {icon} */}
            <span>{title}</span>
            {/* </>  */}
          </ButtonWithChild>
        ))}

      <ul className={`lang__items ${visible ? 'lang__items--active' : ''}`}>
        {languages
          .filter(({ slug }) => slug !== language)
          .map(({ slug, title }) => (
            <li className="lang__item" key={slug}>
              <ButtonWithChild
                onClick={() => changeLanguage(slug)}
                className="f aic"
              >
                {/* <>
                  {icon} */}
                <span>{title}</span>
                {/* </> */}
              </ButtonWithChild>
            </li>
          ))}
      </ul>
    </div>
  );
});
