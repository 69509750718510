module.exports = {
  i18n: {
    defaultLocale: "uk",
    locales: ["en", "ru", "uk"],
    localeDetection: false,
    // domains: [
    //   {
    //     domain: 'localhost:3000',
    //     defaultLocale: 'ru',
    //     http: true,
    //   },
    //   {
    //     domain: 'localhost:3000/uk',
    //     defaultLocale: 'uk',
    //     http: true,
    //   },
    //   {
    //     domain: 'localhost:3000/en',
    //     defaultLocale: 'en',
    //     http: true,
    //   },
    // ],
  },
};
