import { useState, useEffect, useCallback } from 'react';

export const useMediaQuery = (mediaQuery = '(min-width:768px)') => {
  const [isMatch, setMatch] = useState(false);

  const handler = useCallback((e) => {
    setMatch(!!e.matches);
  }, []);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(mediaQuery);

    if (mediaQueryList.matches) setMatch(true);

    try {
      mediaQueryList.addEventListener('change', handler);
    } catch (e) {
      mediaQueryList.addListener(handler);
    }

    return () => {
      try {
        mediaQueryList.removeEventListener('change', handler);
      } catch (e) {
        mediaQueryList.removeListener(handler);
      }
    };
  }, [mediaQuery]);

  return { isMatch };
};
