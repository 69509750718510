import React, { memo, useRef } from "react";

import { CustomNavLink } from "../../CustomNavLink";
import { useTranslation } from "react-i18next";
import { linkByPath } from "../../../utils/link/linkByPath";
import { NavBarRoute } from "..";
import { useOnClickOutside, useToggle } from "../../../hooks";
import classNames from "classnames";

export const HeaderNavItem = memo(
  ({
    titleKey,
    pathname,
    shortPath,
    children,
    to,
    // path: parentPath,
    // isPage = true,
    className = "header-nav__link",
  }: {
    titleKey: string;
    pathname: string;
    path: string;
    shortPath: string[];
    to: string;
    // isPage?: boolean;
    className?: string;
    children: NavBarRoute[];
  }) => {
    const [t] = useTranslation();
    const ref = useRef();

    const [show, toggle, hide] = useToggle();
    useOnClickOutside(ref, hide);

    // const { asPath } = useRouter();
    // const [path, hash] = asPath.split("#");
    const isActive = children.some(({ slug }) => to.includes(slug));

    return (
      <li className="header-nav__items header-nav__item-link" ref={ref}>
        <button
          className={classNames(className, {
            [`${className}--active`]: !!isActive,
          })}
          onClick={toggle}
        >
          {t(titleKey)}
        </button>

        <ul
          className={classNames("header-nav__group-item", {
            "header-nav__group-item--active": show,
          })}
        >
          {children.map(({ slug, titleKey, path, isPage }) => {
            const to = linkByPath({
              isPage,
              path,
              shortPath,
              pathname,
            });

            return (
              <li key={slug} className="header-nav__group-item-link">
                <CustomNavLink
                  to={to}
                  onClick={hide}
                  className="header-nav__group-link"
                >
                  {t(titleKey)}
                </CustomNavLink>
              </li>
            );
          })}
        </ul>
      </li>
    );
  }
);
