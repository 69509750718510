import { useEffect } from "react";
// import { useLocation } from 'react-router-dom';
import { useRouter } from "next/router";

const yOffset = 118;
export const HASH_PREFIX = "_";

function ScrollToAnchor() {
  const { pathname, asPath } = useRouter();

  useEffect(() => {
    let hash = asPath.match(/#([a-z0-9]+)/gi);

    const el =
      hash && document.getElementById(`${HASH_PREFIX}${hash[0].slice(1)}`);

    if (el) {
      const y = el.getBoundingClientRect().top + window.scrollY - yOffset;

      window.scrollTo({ top: y, behavior: "auto" });
    }
  }, [pathname, asPath]);
  return null;
}

export { ScrollToAnchor };
