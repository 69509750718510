import React from 'react';

export const CloseButton = ({ onClick }) => {
  return (
    <div className="close-button" onClick={onClick}>
      <span></span>
      <span></span>
    </div>
  );
};
