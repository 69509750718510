export const PHONE = "+380442217968"; //'+380947102606';
export const PHONE_TITLE = "+38 (044) 221-79-68"; //'+38 (094) 710-26-06';
export const EMAIL = "info@bond.ua";

export const API_URL = `${process.env.SITE_URL}/`;

export const SITE_URL = process.env.SITE_URL;
export const APP_IOS_LINK =
  "https://apps.apple.com/us/app/bond-ua/id1617621292"; //"https://testflight.apple.com/join/vLWvnsqW"
export const APP_ANDROID_LINK =
  "https://play.google.com/store/apps/details?id=com.bond.bondua";
