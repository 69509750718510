import React from "react";

import { Header } from "../Header";
import { Footer } from "../Footer";
import Head from "next/head";
import { useTranslation } from "next-i18next";
import { getSeoFields } from "../../utils/seo/getSeoFields";

export const Layout = ({ children, tags }) => {
  const [t, { language }] = useTranslation();
  const {
    tagTitle = t("header_title"),
    tagDescription = t("header_description"),
    tagKeywords = t("header_keywords"),
    alternate,
    ogType,
    ogSiteName,
    ogTitle = t("header_title"),
    ogDescription = t("header_description"),
    ogImage = "https://bond.ua/banner.jpg",
    ogImageWidth,
    ogImageHeight,
    ogImageAlt,
    // ogImageType,
    ogUrl = `https://bond.ua/${language}`,
    icon,
    iconTouch,
    // googleTag,
  } = getSeoFields({ ...tags });

  const hasOgDescription = !!ogDescription;

  return (
    <>
      <Head>
        <title>{tagTitle}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        {alternate && (
          <link rel="alternate" hrefLang={language} href={alternate} />
        )}
        <link rel="shortcut icon" href={icon} />

        <meta name="theme-color" content="#000000" />
        <meta name="description" content={tagDescription} />
        <meta name="keywords" content={tagKeywords} />

        <link rel="apple-touch-icon" href={iconTouch} />

        <link
          crossOrigin="use-credentials"
          rel="manifest"
          href="/manifest.json"
        />

        <meta property="og:url" content={ogUrl} />
        <meta property="og:type" content={ogType} />
        <meta property="og:site_name" content={ogSiteName} />
        <meta property="og:title" content={ogTitle} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={ogTitle} />
        <meta name="twitter:image" content={ogImage} />
        {hasOgDescription && (
          <meta name="twitter:description" content={ogDescription} />
        )}

        <meta property="og:description" content={ogDescription} />

        <meta property="og:image" content={ogImage} />
        <meta property="og:image:alt" content={ogImageAlt} />
        <meta property="og:image:width" content={ogImageWidth} />
        <meta property="og:image:height" content={ogImageHeight} />
      </Head>
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  );
};
