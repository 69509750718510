import React, { FC } from "react";
import Link from "next/link";

import { HeaderFullNav } from "./HeaderFullNav";

import { HeaderLanguage } from "./HeaderLanguage";
import { useSticky } from "../../hooks/useSticky";

export interface NavBarRoute {
  slug: string;
  order: number;
  titleKey: string;
  path: string;
  isDefault: boolean;
  isPage: boolean;
  shortPath?: string[];
  pathname?: string;
}

interface NavBarParentRoute extends NavBarRoute {
  children?: NavBarRoute[];
}

export const NAVBAR_ROUTES: NavBarParentRoute[] = [
  // {
  //   slug: "bond",
  //   order: 1,
  //   titleKey: "bond_title",
  //   path: `/#about`,
  //   isDefault: true,
  //   isPage: true,
  // },
  // {
  //   slug: "stocks",
  //   order: 2,
  //   titleKey: "paper_title",
  //   path: "/stocks#about",
  //   isDefault: false,
  //   isPage: true,
  // },
  // {
  //   slug: "eurobonds",
  //   order: 2,
  //   titleKey: "euro_bonds",
  //   path: "/eurobonds#about",
  //   isDefault: false,
  //   isPage: true,
  // },
  // {
  //   slug: "quote",
  //   order: 3,
  //   titleKey: "quotation_title",
  //   path: `#quote`,
  //   isDefault: false,
  //   isPage: false,
  // },
  // {
  //   slug: "demo",
  //   order: 4,
  //   titleKey: "demo",
  //   path: "#demo",
  //   isDefault: false,
  //   isPage: false,
  // },
  {
    slug: "news",
    order: 1,
    titleKey: "news_title",
    path: `/`,
    // path: `https://t.me/bond_ua_finance`,
    isDefault: false,
    isPage: true,
  },
  {
    slug: "securities",
    order: 2,
    titleKey: "securities",
    path: "",
    isDefault: false,
    isPage: false,
    children: [
      {
        slug: "bonds",
        order: 1,
        titleKey: "bond_title",
        path: `/bonds#quote`,
        isDefault: false,
        isPage: true,
      },
      {
        slug: "eurobonds",
        order: 2,
        titleKey: "euro_bonds",
        path: "/eurobonds#quote",
        isDefault: false,
        isPage: true,
      },
      {
        slug: "corporate",
        order: 3,
        titleKey: "corporate_bonds",
        path: "/corporate#quote",
        isDefault: false,
        isPage: true,
      },
      {
        slug: "stocks",
        order: 4,
        titleKey: "paper_title",
        path: "/stocks#quote",
        isDefault: false,
        isPage: true,
      },
    ],
  },
  {
    slug: "questions",
    order: 3,
    titleKey: "questions",
    path: `#qa`,
    isDefault: false,
    isPage: false,
  },
  {
    slug: "contacts",
    order: 4,
    titleKey: "contacts_title",
    path: `#contacts`,
    isDefault: false,
    isPage: false,
  },
];

interface Header {
  navbarLinks?: Array<{
    slug: string;
    order: number;
    title: string;
    path: string;
    isDefault: boolean;
  }>;
}

export const Header: FC<Header> = ({ navbarLinks }) => {
  const sticky = useSticky();

  return (
    <header className={`header ${sticky ? "header--active" : ""}`}>
      <div className="container">
        <div className="header-inner">
          <Link href="/">
            <a className="header-brand">
              <img src="/imgs/logo.svg" className="header-logo" alt="logo" />
            </a>
          </Link>

          <HeaderFullNav />
          <HeaderLanguage />
        </div>
      </div>

      <style jsx global>{`
        @import "./Header.scss?4ss";
      `}</style>
    </header>
  );
};
