interface LinkByPath {
  path: string;
  isPage: boolean;
  shortPath: string[];
  pathname: string;
}

export const linkByPath = ({
  path,
  isPage,
  shortPath,
  pathname,
}: LinkByPath): string => {
  return isPage
    ? `${path}`
    : `${
        shortPath.length >= 3 ? shortPath.slice(0, 2).join("/") : pathname
      }${path}`;
};
