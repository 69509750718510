import React, { memo } from "react";
import { APP_ANDROID_LINK, APP_IOS_LINK } from "../../constants";

export const AppLinkBlock = memo(() => {
  return (
    <div className="app-link-block">
      <a
        href={APP_IOS_LINK}
        target="_blank"
        rel="noopener noreferrer"
        className="app-link-item"
      >
        <img src="/imgs/appstore.svg" alt="appstore" />
      </a>
      <a
        href={APP_ANDROID_LINK}
        target="_blank"
        rel="noopener noreferrer"
        className="app-link-item"
      >
        <img src="/imgs/googleplay.svg" alt="playmarket" />
      </a>
    </div>
  );
});
