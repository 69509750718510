import { useCallback, useState } from 'react';

const useToggle = (
  initialState: boolean = false
): [boolean, () => void, () => void] => {
  const [state, setState] = useState(initialState);

  const toggle = useCallback(() => setState((state) => !state), []);
  const off = useCallback(() => setState(false), []);

  return [state, toggle, off];
};

export { useToggle };
