import React, { createContext, useContext, FC } from 'react';
import { useMediaQuery } from '../../hooks/useMediaQuery';

interface ContextState {
  isMatch: boolean | null;
}

const defaultState = {
  isMatch: false,
};

const MediaContext = createContext<ContextState>(defaultState);

export const useMediaContext = () => {
  return useContext(MediaContext);
};

export const ProvideMediaContext: FC = ({ children }) => {
  const isMatchQuery = useMediaQuery();

  return (
    <MediaContext.Provider value={isMatchQuery}>
      {children}
    </MediaContext.Provider>
  );
};
