import axios from "axios";
import { API_URL } from "../constants";

const DEFAULT_HEADERS = {
  "Content-Type": "application/json",
};

export const fetchHandler = async ({
  to,
  locale = "en",
  dataName = "results",
  method = "GET",
  params,

  // queryKey: [
  //   key,
  //   { to, locale = "en", dataName = "results", method = "GET", params },
  // ],
  // options = {},
}) => {
  return await axios(`${API_URL}${to}`, {
    method,

    headers: {
      ...DEFAULT_HEADERS,
      "Accept-Language": locale,
    },
    ...(params && { params }),
    // ...options,
  }).then((res) => {
    return dataName ? res.data[dataName] : res.data;
  });
};
