import React, { memo } from "react";

import { NAVBAR_ROUTES } from "../Header";
import { CustomNavLink } from "../../CustomNavLink";
import { useTranslation } from "react-i18next";
import { Nav } from "../HeaderFullNav";
import { useRouter } from "next/router";
import { linkByPath } from "../../../utils/link/linkByPath";
import { HeaderNavItem } from "../HeaderNavItem/HeaderNavItem";

export const HeaderNav = memo(
  ({
    // hasDemo = false,
    // hasBonds = true,
    // hasEurobonds = true,
    // hasStocks = true,
    // hasCorporates = true,
    hasQA = false,
  }: Nav) => {
    const { pathname } = useRouter();
    const [t] = useTranslation();
    const shortPath = pathname.split("/");

    return (
      <nav className="header-nav">
        <ul className="header-nav__links">
          {NAVBAR_ROUTES.map(({ slug, titleKey, path, isPage, children }) => {
            // if (slug === "demo" && !hasDemo) return null;
            // if (slug === "stocks" && !hasStocks) return null;
            // if (slug === "eurobonds" && !hasEurobonds) return null;
            if (slug === "questions" && !hasQA) return null;

            if (!!children) {
              if (!children.length) return;
              const to = linkByPath({
                isPage,
                path,
                shortPath,
                pathname,
              });

              return (
                <HeaderNavItem
                  key={slug}
                  to={to}
                  titleKey={titleKey}
                  children={children}
                  pathname={pathname}
                  path={path}
                  shortPath={shortPath}
                />
              );
            }

            const to = linkByPath({
              isPage,
              path,
              shortPath,
              pathname,
            });

            return (
              <li key={slug} className="header-nav__item-link">
                <CustomNavLink to={to} className="header-nav__link">
                  {t(titleKey)}
                </CustomNavLink>
              </li>
            );
          })}
        </ul>
      </nav>
    );
  }
);
